import Layout from "components/layout";
import SEO from "components/seo";
import React from "react";
import Miami from "components/locationIndexPages/Miami/index";

function index() {
  const title = "Miami Translation Services - Translation Agency";
  const description =
    "Looking for translation services in Miami? Tomedes provides translation, localization, and interpretation services in 120+ languages with rapid delivery and 24/7 support.";
  const keywords = "Translation Services in Miami";
  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        keywords={keywords}
        slug="/locations/miami-translation-services"
      />
      <Miami />
    </Layout>
  );
}

export default index;
